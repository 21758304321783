<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-card-text class="text-center">
                    <div class="py-3">
                        <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/svg/search.svg" alt="" />
                    </v-avatar>
                    <h1>Passive Vision</h1>
                        <h5 class="font-weight-bold">LETS GET STARTED!</h5>
                        <p class="text--disabled font-weight-medium">
                            Create an account to get access
                        </p>
                    </div>

                    <v-text-field
                        label="email"
                        v-model="email"
                        :rules="emailRules"
                        required
                    />

                    <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        name="input-10-2"
                        label="password"
                        :counter="10"
                        :rules="passwordRules"
                        v-model="password"
                        @click:append="show = !show"
                    ></v-text-field>
                    <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'Confirm Password'"
                        name="input-10-2"
                        label="confirm password"
                        value=""  
                        v-model="confirmPassword"          
                        @click:append="show = !show"            
                    ></v-text-field>
                    <v-checkbox
                        v-model="checkbox1"
                        label="Remember this computer"
                    ></v-checkbox>
                    <v-btn
                        class="mb-4"
                        @click="submit"
                        block
                        color="primary"
                        dark
                        >Sign Up</v-btn
                    >
                    <div class="">
                        Already have an account ?
                        <v-btn
                            text
                            small
                            color="primary"
                            to="/app/sessions/sign-in-two"
                            >Sign In</v-btn
                        >
                    </div>
                </v-card-text>

                <v-snackbar v-model="requiredAlert" top color="danger">
                    Email and Password are required

                    <template v-slot:action="{attrs}">
                        <v-btn
                            color=""
                            text
                            v-bind="attrs"
                            @click="requiredAlert = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar v-model="passwordAlert" top color="danger">
                    Password and Confirm Password must match

                    <template v-slot:action="{attrs}">
                        <v-btn
                            color=""
                            text
                            v-bind="attrs"
                            @click="passwordAlert = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
            </base-card>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import { firebaseAuth  } from '../../../firebase/config'
import {createUserWithEmailAndPassword} from 'firebase/auth'

export default {
    name: 'signup',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignUp 2'
    },
    data() {
        return {
            show: false,
            password: '',
            checkbox1: true,
            checkbox2: false,
            email: '',
            confirmPassword: '',
            loading: false,
            requiredAlert: false,
            passwordAlert: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                (v) => !!v || 'Password is required',
                (v) =>
                    (v && v.length >= 10) ||
                    'Password must be greater than 10 characters'
            ]
        }
    },

    methods: {
        ...mapActions(['signUserUp']),
        submit() {
            if (this.email && this.password && this.password === this.confirmPassword ) {
                this.signUserUp({email: this.email, password: this.ePassword})

                setTimeout(() => {
                    console.log('done')
                }, 1000)
            } else {
                this.snackbar = true
            }
        },
        signUp: function() {
          // console.log(this.email, this.ePassword);
          createUserWithEmailAndPassword(firebaseAuth, this.email, this.ePassword)
            .then(
              user => {
                console.log(user.data);

              },
              err => {
                alert(err);
              }
            )
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
